<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 15:08:48
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-03-16 16:52:18
 * @FilePath: /zhy_dataview_fe/src/components/HomeHead.vue
 * @Description: 头部导航
-->
<template>
  <div class="home-head" :style="`background-image: url(${imgName})`">
    <div class="home-head-left">
      <div class="home-head-left-wrap home-head-left-time">
        <div class="home-head-left-text home-head-left-time-text">
          {{ currentTime }}
        </div>
      </div>
      <div class="home-head-left-wrap home-head-left-date">
        <div class="home-head-left-text home-head-left-date-text">
          {{ currentWeek }}&emsp;{{ currentDate }}
        </div>
      </div>
    </div>
    <div class="home-head-center">一体化融合通讯平台</div>
    <div class="home-head-right">
      <el-dropdown>
        <span class="el-dropdown-link">
          更多操作
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item disabled>
              {{ (userInfo && userInfo.userName) || "暂无用户名" }}
              {{ (userInfo && `（${userInfo.phone}）`) || "" }}
            </el-dropdown-item>
            <el-dropdown-item class="el-dropdown-basic" @click="reload">
              刷新监控缓存
            </el-dropdown-item>
            <el-dropdown-item
              :class="`el-dropdown-${exit3ds ? 'basic' : 'exit'}`"
              @click="exit3D"
            >
              {{ exit3ds ? "开启" : "关闭" }}三维模型
            </el-dropdown-item>
            <el-dropdown-item
              divided
              class="el-dropdown-basic"
              @click="$JX.appMixmum()"
            >
              最小化窗口
            </el-dropdown-item>
            <el-dropdown-item divided class="el-dropdown-exit" @click="logout">
              退出登录
            </el-dropdown-item>
            <el-dropdown-item divided class="el-dropdown-exit" @click="exit">
              退出终端
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import day from "utils/day.js";
import { ArrowDown } from "@element-plus/icons";
export default {
  name: "HomeHead",
  data() {
    return {
      imgName: require("../assets/image/components/HomeHead/home-background.png"),
      currentTime: day(new Date()).format("HH:mm:ss"),
      currentWeek: this.$utils.getWeekDate(),
      currentDate: day(new Date()).format("YYYY年MM月DD日"),
      userInfo:
        (localStorage.getItem("USER_INFO") &&
          JSON.parse(localStorage.getItem("USER_INFO"))) ||
        null,
      exit3ds: JSON.parse(localStorage.getItem("EXIT_3D")),
    };
  },
  computed: {},
  components: {
    ArrowDown,
  },
  props: {
    isHome: {
      default: false,
    },
  },
  methods: {
    getCurrentTime() {
      if (this.$config.dev) return;
      this.timer = setInterval(() => {
        const currentTime = day(new Date()).format("HH:mm:ss");
        const currentWeek = this.$utils.getWeekDate();
        const currentDate = day(new Date()).format("YYYY-MM-DD");
        this.currentTime = currentTime;
        this.currentWeek = currentWeek;
        this.currentDate = currentDate;
      }, 1 * 1000);
    },
    reload() {
      location.reload();
    },
    exit3D() {
      localStorage.setItem("EXIT_3D", !this.exit3ds);
      this.reload();
    },
    logout() {
      this.$confirm("是否需要退出当前账号？", "提示")
        .then(() => {
          localStorage.removeItem("ACCESS_TOKEN");
          localStorage.removeItem("USER_INFO");
          localStorage.removeItem("ACCESS_PERMISSIONS");
          this.$JX.logout();
          this.$router.push("/login");
        })
        .catch(() => {});
    },
    exit() {
      this.$confirm("是否要完全退出软终端？", "提示").then(() => {
        this.$JX.appQuit();
      });
    },
  },
  created() {
    this.getCurrentTime();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.home-head {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 1920px;
  height: 88px;
  background-size: 100%;
  background-repeat: no-repeat;
  animation: fadeInDown 0.5s both;

  .home-head-left {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 14px;
    left: 20px;
    width: 318px;
    height: 28px;

    .home-head-left-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .home-head-left-text {
      color: #ffffff;
    }

    .home-head-left-time {
      width: 112px;
      height: 28px;

      .home-head-left-time-text {
        font-size: 24px;
      }
    }

    .home-head-left-date {
      width: 204px;
      height: 28px;

      .home-head-left-date-text {
        font-size: 16px;
      }
    }
  }

  .home-head-center {
    position: absolute;
    left: 50%;
    margin-left: -174px;
    top: 25px;
    width: 348px;
    height: 37px;
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #e4eaff;

    background: linear-gradient(180deg, #e7ffff 0%, #2463cc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home-head-right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
    top: 10px;
    right: 0;
    width: 404px;
    height: 45px;

    .el-dropdown-link {
      cursor: pointer;
    }

    .home-head-right-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.5;
      }
    }

    .home-head-right-text {
      color: #ffffff;
      font-size: 16px;
    }

    .home-head-right-icon {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      background-size: 100%;
      background-repeat: no-repeat;
    }

    .home-head-right-user {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 270px;
      height: 43px;
      margin-left: 15px;

      .home-head-right-user-icon {
        background-image: url("../assets/image/components/HomeHead/user-icon.png");
      }

      .home-head-right-user-info {
        display: flex;

        .home-head-right-user-info-name {
          margin-right: 20px;
        }
      }
    }

    .home-head-right-logout {
      width: 120px;
      height: 43px;

      .home-head-right-logout-icon {
        background-image: url("../assets/image/components/HomeHead/quit-icon.png");
      }
    }
  }
}

.el-dropdown-menu {
  padding: 0;
}

.el-dropdown-menu__item.is-disabled {
  color: #ffffff;
}

.el-dropdown-menu__item--divided:before {
  height: 1px;
}

.el-dropdown-menu__item--divided {
  margin-top: 0;
  border-top: 0;
}

.el-dropdown-basic {
  color: var(--el-color-primary);
  text-align: center;
  font-weight: bold;
}

.el-dropdown-exit {
  color: red;
  text-align: center;
  font-weight: bold;
}
</style>
